.content {
  margin: 10%;
}

.homeHeader {
  font-family: "Montserrat", sans-serif;
  font-size: calc(1px + 7vw);
  text-align: center;
  margin: 0;
}

.homeSubheader {
  font-family: "Montserrat", sans-serif;
  font-size: calc(1px + 3vw);
  text-align: center;
  margin: 0;
  margin-bottom: 1vw;
}

.homeEmailHeader {
  font-family: "Inconsolata", monospace;
  font-size: calc(1px + 1.5vw);
  text-align: center;
  margin: 0;
  margin-bottom: 9vw;
}

.homeTopicHeader {
  font-family: "Montserrat", sans-serif;
  font-size: calc(1px + 3vw);
  margin-bottom: 3vw;
  margin-top: 3vw;
}

.aboutLink {
  font-family: "Inconsolata", monospace;
  font-size: calc(1px + 2vw);
  color: black;
}

.blogPreview {
  margin: 1vw;
  padding: 2vw;
  border: solid;
  border-radius: 1px;
}

.blogPreviewHeader {
  font-family: "Montserrat", sans-serif;
  font-size: calc(1px + 2vw);
  color: black;
}

.blogPreviewInfo {
  font-family: "Inconsolata", monospace;
  font-size: calc(7px + 1vw);
  margin: 0;
}

.blogPreviewParagraph {
  font-family: "Inconsolata", monospace;
  font-size: calc(7px + 1vw);
}

.goBack {
  font-family: "Montserrat", sans-serif;
  font-size: calc(5px + 1vw);
  color: black;
}

.header {
  font-family: "Montserrat", sans-serif;
  font-size: calc(20px + 6vw);
  margin: 0;
}

.info {
  font-family: "Inconsolata", monospace;
  font-size: calc(7px + 1.5vw);
  margin: 0;
}

.paragraph {
  font-family: "Inconsolata", monospace;
  font-size: calc(7px + 2vw);
}

.aboutHeader {
  font-family: "Montserrat", sans-serif;
  font-size: calc(20px + 6vw);
  margin: 0;
}

.aboutSubheader {
  font-family: "Montserrat", sans-serif;
  font-size: calc(1px + 3vw);
  text-align: center;
}

.aboutSplit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .aboutSplit {
    flex-direction: column-reverse;
  }
}

.aboutLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.aboutRight {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.aboutImage {
  width: 100%;
  margin-left: 2vw;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.nonBorderedContainer {
  display: flex;
  justify-content: center;
}

.borderedContainer {
  display: flex;
  justify-content: center;
  border: solid;
}

@media only screen and (max-width: 900px) {
  .aboutImage {
    margin-left: 0;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
